<template>
  <div class="overview-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in searchOptions"
          :key="i"
          href="#"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
          @click="sideColClicked(i)"
        >
          {{ col.name }}
        </a>
      </ul>
    </div>
    <div class="content-wrapper">
      <b-breadcrumb :items="crumb_links"></b-breadcrumb>
      <h2>{{ overviewTitle }}</h2>
      <p>{{ description }}</p>
      <div class="options-panel">
        <div
          v-for="(option, i) in searchOptions"
          :key="i"
          class="search-option"
        >
          <h3 class="pg-link" @click="sideColClicked(i)">{{ option.name }}</h3>
          <p>{{ option.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDefaultJBrowseUrl, getDefaultJBrowse2Url } from "@/services/JBrowseService";

export default {
  name: "ToolsOverview",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Tools",
          active: true,
        },
      ],
      overviewTitle: "Tools Overview",
      description:
        "This page provides links to all the analysis and visualization tools implemented at TAIR or in collaboration with TAIR elsewhere.",
    };
  },
  computed: {
    searchOptions() {
      return [
        {
          name: "GO Term Enrichment",
          description:
            "This tool uses the PANTHER (www.panther.org) Gene Classification system to find statistically over and under represented GO terms in sets of genes. Can be used for Arabidopsis and other plant species included in the PANTHER gene set.",
          route:
            process.env.VUE_APP_OLD_TAIR_URL + "/tools/go_term_enrichment.jsp",
        },
        {
          name: "JBrowse",
          description:
            "JBrowse is a fast, scalable genome browser built completely with JavaScript and HTML5. Search or browse a map of the Arabidopsis genome (including genes, cDNAs and ESTs, insertion mutants, SNPs, markers and BACs) or upload your own annotation track. JBrowse is a GMOD project and its development is funded by the NHGRI. Help pages are available.",
          route: getDefaultJBrowseUrl(),
        },
        {
          name: "JBrowse2",
          description:
            "JBrowse2 is a complete rewrite of JBrowse 1 with a similar user interface but a modern software architecture (Diesh et al. 2023). This more modern browser is under active development and maintenance, and features the capability for viewing genomic structural variants and evolutionary relationships among genes and genomes with syntenic visualizations.",
          route: getDefaultJBrowse2Url(),
        },
        {
          name: "PhyloGenes",
          description:
            "PhyloGenes displays pre-computed phylogenetic trees of gene families alongside experimental gene function data to facilitate inference of unknown gene function in plants.",
          route: `${process.env.VUE_APP_PHYLOGENES}`,
        },
        {
          name: "GBrowse",
          description:
            "A popular and versatile genome browser now available at TAIR. Search or browse a map of the Arabidopsis genome (including genes, cDNAs and ESTs, insertion mutants, SNPs, markers and BACs) or upload your own annotation track. GBrowse was developed by Lincoln Stein and the software is available from GMOD. Help pages are available.",
          route:
            "https://gbrowse.arabidopsis.org/cgi-bin/gb2/gbrowse/arabidopsis/",
        },
        {
          name: "Synteny Viewer",
          description:
            "SyntenyViewer is a tool to display precomputed syntenic regions between A.thaliana and related genomes. The syntenic regions between Athaliana and the other genomes have been precomputed using the SynMap tool at genomevolution.org. The syntenic regions are displayed using the GeVo tool from genomevolution.org.",
          route:
            process.env.VUE_APP_OLD_TAIR_URL +
            "/cgi-bin/syntenyviewer2/showSynteny.pl",
        },
        {
          name: "SeqViewer",
          description:
            "A genome browser developed at TAIR for viewing Arabidopsis sequence and annotation. Search for names or short sequences and view search hits on the whole genome, in a closeup view or at the nucleotide level. Tracks include genes, cDNAs and ESTs, insertion mutants, SNPs, markers and BACs. Help pages are also available.",
          route: `${process.env.VUE_APP_SEQVIEWER}`,
        },
        {
          name: "MapViewer",
          description:
            "TAIR's map comparison tool designed for viewing and comparing genetic, physical and sequence maps. Please read the release notes before using this tool.",
          route: process.env.VUE_APP_OLD_TAIR_URL + "/servlets/mapper",
        },
        {
          name: "AraCyc Metabolic Pathways",
          description:
            "Arabidopsis biochemical pathways visualization and querying tool hosted by the Plant Metabolic Network.",
          route: "https://pmn.plantcyc.org/organism-summary?object=ARA",
        },
        {
          name: "Integrated Genome Browser",
          description:
            "Integrated Genome Browser (IGB) is a fast, flexible, and free genome browser you can use to explore & visually analyze vast genomic data. IGB runs on your desktop and loads data from local files or the internet.",
          route: "https://bioviz.org/",
        },
        {
          name: "BLAST",
          description:
            "Use your nucleotide or peptide sequence to search against all public Arabidopsis sequences, several subsets of them, or all higher plant sequences from GenBank. These datasets can be downloaded by FTP.",
          route: process.env.VUE_APP_OLD_TAIR_URL + "/Blast/",
        },
        {
          name: "Pattern Matching",
          description:
            "Search TAIR's Arabidopsis dataset for short (< 20 residues) nucleotide or peptide sequences, or ambiguous/degenerate patterns.",
          route:
            process.env.VUE_APP_OLD_TAIR_URL +
            "/cgi-bin/patmatch/nph-patmatch.pl",
        },
        {
          name: "Motif Analysis",
          description:
            "AFGC's Motif Finder gets a facelift and updated genome annotation dataset. This tool allows you to find overrepresented 6-mer oligos in upstream regions of genes.",
          route:
            process.env.VUE_APP_OLD_TAIR_URL +
            "/tools/bulk/motiffinder/index.jsp",
        },
        {
          name: "Chromosome Map Tool",
          description: "Draw maps of your favorite gene family.",
          route:
            process.env.VUE_APP_OLD_TAIR_URL + "/jsp/ChromosomeMap/tool.jsp",
        },
        {
          name: "Restriction Analysis",
          description:
            "Performs restriction analysis on any entered DNA sequence. Hosted at www.yeastgenome.org (SG).",
          route: "https://www.yeastgenome.org/restrictionMapper",
        },
        {
          name: "Textpresso Full Text",
          description:
            "Textpresso is an information extracting and processing package for biological literature. Textpresso for Arabidopsis allows users to search all abstracts and over 15,700 full-text publications in TAIR",
          route: "https://arabidopsis.textpresso.org/tpc/home",
        },
      ];
    }
  },
  mounted() {
    document.title = "Tools Overview";
  },
  methods: {
    sideColClicked(index) {
      window.open(this.searchOptions[index].route, "_blank");
    },
  },
};
</script>

<style scoped lang="scss"></style>
